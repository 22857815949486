<template>
  <mcb-detail-popup :title="mutableValue.login" @save="saveHandle" @esc="cancelHandle">
    <el-form class="manager" ref="form" :rules="rules" :model="mutableValue" label-position="left" label-width="120px">
      <el-form-item label="логин" prop="login">
        <el-input v-model="mutableValue.login" />
      </el-form-item>

      <el-form-item label="Роль">
        <el-select v-model="mutableValue.role">
          <el-option v-for="(item, key) in roleOptions" :key="key" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
    </el-form>

    <el-alert title="Пароль по умолчанию: 1234" type="warning" effect="dark" :closable="false" v-if="!mutableValue.id" />
  </mcb-detail-popup>
</template>

<script>
import { roleOptions } from '@/components/utils.js'
import DetailPage from '@/mixins/detail-page.js'

export default {
  mixins: [DetailPage],

  data () {
    return {
      roleOptions: roleOptions,
      rules: {
        login: [{ required: true, message: 'Пожалуйчта введите логин', trigger: 'blur' }],
      }
    }
  }
}
</script>
